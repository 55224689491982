/**
 * Nag Modal Functionality
 *
 * In order to launch the modal, use the following markup:
 *
 * <a class="btn btn-primary"
 * data-nag="Whatever the title is"                // This can be overwritten in the modal
 * data-route="{{ route('<app_area>.nag', id) }}"  // e.g. route('bucketlist.nag', bucketItem->id)
 * >
 * <i class="fa fa-bell"></i></a>
 */

var Nag = {

    route: null,
    bucketTitle: null,
    $modal: null,
    month: [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    reload: false,
    init: function($modal, data) {
        var that = this;
        this.bucketTitle = data.nag;
        this.$modal = $modal;
        this.$modal.find('input[name="reminder_message"]').val(data.nag);
        this.route = data.route;
        this.getNag();
        this.$modal.on('click', '[data-save-nag]', function(e) {
            that.save();
            e.preventDefault();
            return false;
        });
        if(data.reload) {
            this.reload = true;
        }
        this.$modal.find('.date-picker-format').datetimepicker({
            format: 'DD/MM/YYYY',
            extraFormats: [ 'YYYY-MM-DD' ]
        });
    },

    // Gets the nag details from the server (if nag set)
    getNag: function() {
        var that = this;
        // Get nag data (if set)
        $.get(this.route, function(response) {
            // Populate the modal if repsonse has a nag item
            if(typeof response == 'object') {
                that.populateForm(response);
            }
        }).complete(function(){
            that.$modal.modal('show');
        });
    },
    // Populates the nag form
    populateForm: function(response) {
        this.$modal.find('input[name=reminder_message]').val(response.reminder_message);
        this.$modal.find('input[name=_method]').val('PUT');
        this.$modal.find('input[name=start_date]').val(moment(response.start_date).format('DD/MM/YYYY'));
        this.$modal.find('input[name=end_after_date]').val(moment(response.end_after_date).format('DD/MM/YYYY'));
        var frequency = response.frequency;
        this.$modal.find('select[name=frequency]').val(frequency).trigger('change');
        if(frequency == 'yearly') {
            var frequency_day = response.frequency_value.substr(0,2);
            var frequency_month = response.frequency_value.substr(2);
            this.$modal.find('select[name="frequency_value[yearly][day]"]').val(Number(frequency_day));
            this.$modal.find('select[name="frequency_value[yearly][month]"]').val(this.month.indexOf(frequency_month + 1));
        } else {
            this.$modal.find('select[name="frequency_value['+frequency+']"]').val(response.frequency_value);
        }

        if(frequency == "once") {
            this.$modal.find('[name="frequency_value[once]"]').val(moment(response.frequency_value).format('DD/MM/YYYY'));
        }
    },
    // Saves the nag
    save: function() {
        var that = this;
        // Format the frequency string
        var frequency = this.$modal.find('[name="frequency"]').val();
        if( frequency == 'yearly' ) {
            // Get the parts
            var day = $('[name="frequency_value[yearly][day]"]').val();
            var monthNum = $('[name="frequency_value[yearly][month]"]').val();
            // BARF
            if(day < 10) day = "0" + day; monthNum--;
            var frequency_value =  day + this.month[monthNum];
        } else {
            var frequency_value = $('[name="frequency_value[' + frequency + ']"]').val();
        }
        $.ajax({
            method: 'POST',
            url: this.route,
            data: {
                '_token': this.$modal.find('input[name="_token"]').val(),
                'reminder_message': this.$modal.find('input[name="reminder_message"]').val(),
                'start_date': this.$modal.find('input[name="start_date"]').val(),
                'end_after_date': this.$modal.find('input[name="end_after_date"]').val(),
                '_method': this.$modal.find('input[name="_method"]').val(),
                'frequency' : frequency,

                'frequency_value' : frequency_value,
            },
            dataType: 'json',
            success: function(data) {
                that.$modal.modal('hide');
                that.$modal.off('click', '[data-save-nag]');
                // Clear inputs
                that.$modal.find('input[type=text], select').each(function(){
                    $(this).val('');
                });
                $('button[data-save-nag="'+that.route+'"], a[data-save-nag="'+that.route+'"], button[data-route="'+that.route+'"], a[data-route="'+that.route+'"]').addClass('c-secondary');
                if(that.reload === true) {
                    location.reload();
                }
                swal('Saved!', 'Nag saved successfully');
            },

            error: function(data) {
                swal('Error!', data.responseJSON.error, 'error');
            }
        });
    }
};

// Launch nag modal
$(document).on('click', 'a[data-nag], button[data-nag]', function(e) {
    e.preventDefault();
    var nag = Nag.init($('#nagMeModal'), $(this).data());
});

// Select enabler
$(document).ready(function(){
    $('body').on('change','select.enabler', function() {
        var name = this.name;
        var value = this.value;
        $('[data-enabled-by="' + name + '"]').hide();
        $('[data-enabled-by="' + name + '"][data-enabled-value*="' + value + '"]').show();
    });
    $('select.enabler').trigger('change');
});