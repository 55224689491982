/**
 *
 *  Ajax Form Scripts
 *  =================
 *
 * These scripts are linked to events triggered when submitting forms with data-remote attributes.
 * Models are objects that contain the functionality related to post-success events and are referenced through data
 * attributes on the button/form that called them. e.g.
 * data-model="AddressBook" This object is stored in the Models object
 * data-remote-on-success="updateRow" This method be stored in the Models.AddressBook object
 *
 * The markup to call this function would be as follows:
 *
 * <form 
 *     data-remote                              // Required to trigger the following functionality
 *     data-model="AddressBook"                 //  Set the model
 *     data-remote-on-success="updateRow"       // Assign the post success method
 *     data-remote-success-message="Complete!"  // Optional message to show once the task is complete
 * >
 *
 *
 * Confirmation Messages
 * =====================
 * 
 *  In order to use the confirmation messages use the following markup:
 *  
 *  <button/input type="submit"
 *      data-confirm="Are you sure"  // Triggers a confimation box before a form is submitted or other action is taken
 *  >
 * 
 * 
 */
(function(){

     /**
     * Models object contains all methods called by the dynamic data-* attributes on forms
     * In order for dynamic object names to work ([objectName]) we have to have a container object,
     * otherwise we have to assign it to the window object which we don't want to do.
     */
    
    var Models = {
        // Address book in profile area
        AddressBook : {
            addToAssets : function(e, args) {
                console.log('Adding to assets modal');
            },
            // Adds a new contact in profile/yourcontacts 
            addContact : function(e, args) {
                var result = JSON.parse(args.r);
                // Hide the modal
                var form = $(e);
                form.parents('.modal').modal('hide');
                // Set the tabs
                if(result.contact.type == 'person') {
                    var tab = 'contacts-people';
                } else {
                    var tab = 'contacts-organisations';
                }
                // Add the row
                var table = $('#'+tab+' tbody');
                Models.AddressBook.addTableRow(table, result.contact);
                // Switch the tab
                $('a[href=#'+tab+']').tab('show');

                this.refreshContactModal();

            },
            refreshContactModal: function() {
                // Refresh the contact select modal
                // Check to see if we have a route
                if(!$('[data-refresh-contact-select]').length) {
                    // Fall back on refreshing the page
                    location.reload();
                }
                var modal = $('.contactSelectModal');
                var refreshRoute = $('[data-refresh-contact-select]').data('refreshContactSelect');
                $.get(refreshRoute, function(){
                }).success(function(data){
                    modal.replaceWith(data);
                });
            },
            // Updates the contact in the modal
            updateContact : function(e, args) {
                // Parse results
                var result = JSON.parse(args.r);
                // Hide the modal
                var form = $(e);
                form.parents('.modal').modal('hide');
                // Update row
                var row = $('tr[data-entry-id=' + result.contact.id + ']');
                row.html('');
                Models.AddressBook.fillTableRow(row, result.contact);
                // Refresh the contact select modal
                Models.AddressBook.refreshContactModal();
            },
            // Removes the contact from the modal
            removeContact : function(e, args) {
                // Parse results
                var result = JSON.parse(args.r);
                // Remove row
                var row = $('tr[data-entry-id=' + result.id + ']');
                var tbody = row.parent('tbody');
                row.remove();
                // Check for any tables existing
                if(tbody.find('tr').length === 0) {
                    // append the no contacts message
                    tbody.append('<tr><td colspan="14"><div class="alert alert-info">You have no contacts in your address book</div></td></tr>');
                }
                // Remove from trusted people if there
                $('.trusted-person-card[data-contact-id='+result.id+']').remove();
                // Refresh the contact select modal
                Models.AddressBook.refreshContactModal();
            },
            // Add person to the modal in the questionnaire
            addToPersonModal : function(e, args) {
    
                // Parse results
                var result = JSON.parse(args.r);

                // Append to modal
                var modal = $(e).parents('.modal');

                // Build up html of contact card
                var contactContainer = $('<div/>').attr({
                    'class': 'contact-container'
                }).html('<div class="contact-actions pull-right"></div>');
                
                // Create edit button
                var btn = $('<button/>').attr({
                    'data-edit-contact': '',
                    'data-contact-id': result.contact.id,
                    'class': 'btn btn-default'
                }).html('<i class="fa fa-pencil"></i>');
                btn.appendTo(contactContainer.find('.contact-actions'))

                // Build up contact card
                var contactCard = $('<div/>').attr({
                    'class': 'contact-card',
                    'data-add-contact' : '',
                    'data-clearable' : '',
                    'data-contact-id' : result.contact.id
                });
                if(result.contact && result.contact.email && result.contact.email) {
                    contactCard.attr('data-has-email', 'has-email');
                }



                if(result.contact.type == 'company') {
                    // Hilariously called company in db and not everywhere else
                    var type = 'organisation';
                    var tab = $('.contactSelectModal.in').find('.tab-pane.'+type);

                    contactCard.html('<div class="title">' + result.contact.company_name + '</div>\
                        <div class="body">\
                            <div class="row">\
                                <div class="col-sm-6">'+result.contact.address_1+'</div>\
                                <div class="col-sm-6">'+result.contact.email+'</div>\
                            </div>\
                        </div>');
                } else {
                    var type = result.contact.type;
                    var tab = $('.contactSelectModal.in').find('.tab-pane.'+type);
                    contactCard.html('<div class="title">' + result.contact.first_name + ' ' + result.contact.last_name + '</div>\
                            <div class="body">\
                                <div class="row">\
                                    <div class="col-sm-6">'+result.contact.address_1+'</div>\
                                    <div class="col-sm-6">'+result.contact.email+'</div>\
                                </div>\
                            </div>');
                }

                // Add to container
                contactCard.appendTo(contactContainer);
                contactContainer.appendTo(tab);

                // Hide the no person alert
                if(tab.find('.alert').length > 0) {
                    tab.find('.alert').hide();
                }
            
                // Add to the addressBook array
                if(typeof $q != 'undefined') {
                    $q.addressBook.push(result.contact);
                }

                // Hide the modal
                modal.modal('hide');

                //select that contact
                $('[data-add-contact][data-contact-id="' + result.contact.id + '"]').trigger('click');
            },
            // Update the edited contact 
            updateSelectContactModal: function(e, args) {
                // Parse results
                var result = JSON.parse(args.r);
                var $selectModal = $('#contactSelectModal.in');
                $card = $selectModal.find('.contact-card[data-contact-id='+result.contact.id+']');
                $card.find('.name').text(result.contact.first_name + ' ' + result.contact.middle_name + ' ' + result.contact.last_name);
                $card.find('.address_1').text(result.contact.address_1);
                $card.find('.email').text(result.contact.email);
                if(result.contact.email && result.contact.email.length) {
                    // We have an email, so data attribute has to be true
                    $card.attr('data-has-email', 'has-email');
                } else {
                    $card.attr('data-has-email', 'no-email');
                }
                $(e).parents('.modal').modal('hide');
            },
            // Add the new row
            addTableRow : function( table, data ) {
                table.append('<tr data-entry-id="'+data.id+'"></tr>');

                // Remove any no contact alerts
                if(table.find('tr.no-contacts').length) {
                    table.find('.no-contacts').remove();
                }

                var tr = $('tr[data-entry-id="'+data.id+'"]');
                Models.AddressBook.fillTableRow(tr, data);
            },
            // Fill the contents of the row
            fillTableRow : function( tr, data ){
                
                // Set CSFR Token
                var token = tr.parents('table').data('token'),
                    route = tr.parents('table').data('route');

                // Set Edit/Delete HTML
                var actions = '<td>\
                    <button class="btn btn-primary" data-modal="#contactModal" data-route="'+route+'/'+data.id+'/edit"><i class="fa fa-fw fa-edit"></i></button>\
                    <form method="POST" action="'+route+'/'+data.id+'" accept-charset="UTF-8" style="display:inline" data-remote="data-remote" data-remote-on-success="removeContact" data-model="AddressBook"><input name="_method" type="hidden" value="DELETE"><input name="_token" type="hidden" value="'+token+'"><button class="btn btn-danger" data-confirm="Do you want to delete this contact?"><i class="fa fa-fw fa-trash-o"></i></button></form>\
                    </td>';

                // Append to tab
                if(data.type == 'person') {

                    // Make the date look nice
                    if(data.dob && data.dob.indexOf('-')) {
                        data.dob = data.dob.substr(0,10).split('-').reverse().join('/')
                    }
                    // Some more formatting
                    data.dob = data.dob ? data.dob : '';


                    tr.append('<td>'+data.title+'</td>\
                    <td>'+data.first_name+'</td>\
                    <td>'+data.middle_name+'</td>\
                    <td>'+data.last_name+'</td>\
                    <td>'+data.gender+'</td>\
                    <td>'+data.relationship+'</td>\
                    <td>'+data.dob+'</td>\
                    <td>'+data.email+'</td>'
                    + actions);
                    
                } else {
                    tr.append('<td>'+data.company_name+'</td>\
                    <td>'+data.company_no+'</td>\
                    <td>'+data.tel_1+'</td>\
                    <td>'+data.email+'</td>'
                    + actions);    
                }

                
            },
        },
        Assets : {
            deleteFromAssets : function(e, args) {
                $(e).parents('tr').remove();
            },
        },
        ContactRequest : {
            showSuccess : function(e, args) {
                $(e).find('[type=submit]').prop('disabled', false);
                $(e).parents('.modal').modal('hide');
                swal({
                    'text': 'Sent',
                    'type': 'success'
                });
            }
        },
        Uploads : {
            removeRow : function(e, args) {
                $(e).parents('li').remove();
                swal({
                    'text': 'File deleted',
                    'type': 'success'
                });
            },
            removeQuickUploadRow : function(e, args) {
                $(e).parents('tr').remove();
                // Update count
                var c = $('[data-target="#quickUploads"]').find('span.count');
                var n = Number(c.text());
                n--;
                c.text(n);
                swal({
                    'text': 'File deleted',
                    'type': 'success'
                });
            }

        }
    };



    /**
     * PubSub functionality for data-remote actions on forms
     */
    // Quickie PubSub
    var o = $({});
    $.subscribe = function() { o.on.apply(o, arguments); };
    $.publish = function() { o.trigger.apply(o, arguments); };

    // Async submit a form's input.
    var submitLaravelRequest = function(e) {
        var form = $(this);
        var method = form.find('input[name="_method"]').val() || 'POST';
        form.prop('disabled', true);
        form.find('[type=submit]').prop('disabled', true);
        // validate any dates
        form.find('input[type=date]').each(function() {
            if(form.find('input[type=date]').val().indexOf('/')) {
                var d = $(this).val().split('/');
                $(this).val(d.reverse().join('-'));
            }
        }).promise().done(function() {
            $.ajax({
                type: method,
                url: form.prop('action'),
                data: form.serialize(),
                success: function(r) {
                    $.publish('ajax.request.success', { form: form, r: r });
                }
            });
        });
        e.preventDefault();
    };


    // Offer flash notification messages.
    // 'data-remote-success-message' => 'Yay. All Done.'
    $.subscribe('ajax.request.success', function(e, form) {
        var message = $(form).data('remote-success-message');
        if (message) {
            $('<div />', { class: "flash alert alert-success" }).appendTo('body').html(message).fadeIn(300).delay(3000).fadeOut(300);
        }
    });


    // Handle success callbacks. To trigger Task.foo(), do:
    // 'data-model' => 'Task', 'data-remote-on-success' => 'foo'
    $.subscribe('ajax.request.success', function(e, args) {
        triggerClickCallback.apply(args, [e, $(args.form).data('remote-on-success')]);
    });

    // Confirm an action before proceeding.
    var confirmAction = function(e) {

        var input = $(this);

        input.prop('disabled', 'disabled');

        if ( ! confirm(input.data('confirm'))) {
            e.preventDefault();
        }

        input.removeAttr('disabled');
    };

    // Trigger the registered callback for a click or form submission.
    var triggerClickCallback = function(e, method) {
        var that = this;
        var form = $(that.form);

        // What's the name of the parent model/scope/object.
        if ( ! (model = form.closest('*[data-model]').data('model'))) {
            return;
        }
        
        // As long as the object and method exist, trigger it and pass through the form.
        if (typeof Models[model] == 'object' && typeof Models[model][method] == 'function') {
            Models[model][method](form, that);
        } else {
            console.error('Could not call method ' + method + ' on object ' + model);
        }
        e.preventDefault();
    };

    // Dom bindings.
    $(document).on('submit', 'form[data-remote]', submitLaravelRequest);
    $(document).on('click', 'input[data-confirm], button[data-confirm]', confirmAction);
    $(document).on('click', '*[data-click]', function(e) {
        triggerClickCallback.apply(this, [e, $(this).data('click')]);
    });

    // Remove flash alerts
    $('.alert.flash').delay(5000).fadeOut('fast');

})();