/**
 * Script to launch referral modal
 * @param  object options Routes and token
 * @return void
 */
var initReferrals = function(options){
	/**
	 * Show referral modal
	 */
	$(document).on('click', 'a[data-referral-id], button[data-referral-id]', function(e) {
		e.preventDefault();
		var $btn = $(this);
		var $modal = $('#referralModal');
		// Disable the button
		$btn.attr('disabled', 'disabled');
		var referral = $btn.attr('data-referral-id');

		// Get the location name
		var location = 'Outside questionnaire';
		if($('[data-questionnaire-title]').length) {
			location = $('[data-questionnaire-title]').attr('data-questionnaire-title');
		}

		// Get the modal contents
		$.get(options.modalRoute + '/' + referral, function(data) {
			// Set the modal message
			$modal.find('.modal-body').html(data);
			var $sendBtn = $modal.find('[data-send-referral]');
			// Set data attributes on send button
			$sendBtn.attr('data-referral-link-id', 
				$modal.find('.modal-body [data-referral-link-id]').attr('data-referral-link-id')
			);
			$sendBtn.attr('data-referral-type-id', 
				$modal.find('.modal-body [data-referral-type-id]').attr('data-referral-type-id')
			);
			$sendBtn.attr('data-location', location);
		}).complete(function(){
			$('#referralModal').modal('show');
		});
	});

	/**
	 * Submit Referral
	 */
	$(document).on('click', 'button[data-send-referral], a[data-send-referral]', function(e) {
		e.preventDefault();
		var $this = $(this);
		$.post(options.submitReferralRoute, {
			referralId: $this.attr('data-referral-link-id'),
			referralTypeId: $this.attr('data-referral-type-id'),
			location: $this.attr('data-location'),
			_token: options.token,
		}, function(data) {
		}).success(function(){
			// Hide the modal
			$('#referralModal').modal('hide');
			// Show success
			swal('Thanks','Your action has been saved','success');
		});
	});


	/**
	 * Reset any referral buttons on hiding the modal
	 */
	$('#referralModal').on('hidden.bs.modal', function (e) {
		$('a[data-referral-id], button[data-referral-id]').attr('disabled', false);
	});

};

