/**
 *
 *  Modal Scripts
 *  =============
 *
 * These scripts are linked to events triggered in various modals across the system 
 * 
 */

var modals = function() {

    // Set here if we are checking for duplicate contacts and disable them
    // we can override this on the panel if required. It was taken out
    // August 2016 as in some instances we need to be able to add
    // the same contact multiple times to a question.
    var checkForDuplicateContacts = false;

    function initDates() {
        $('.date-picker-format').datetimepicker({
            format: 'DD/MM/YYYY',
            extraFormats: [ 'YYYY-MM-DD' ]
        });
    }

    var ModalMethods = {
        // Add to open asset modal from contact select modal
        addToAssetPanel : function(btn, modal) {
            var target = $('#categoryModal.in');
            target.find('input[name=address_id]').val(btn.attr('data-contact-id'));
            target.find('span.company_name').text(btn.find('.title').text()).show();
            target.find('button[data-contact-type]').text('Change Organisation');
            // Close the modal
            modal.modal('hide');
        }
    }

    // Sometime, data-target messes up because NO REASON ARGHHH
    $(document).on('click', '[data-modal-launch]', function(event) {
        var modalSelector = $(this).attr('data-modal-launch');
        if(typeof modalSelector !== 'undefined') {
            $(modalSelector).modal('show');
        } else {
            alert('Modal not found');
        }
    });

    /**
     * Modal Scripts. Run when modal with data-modal set are shown.
     */
    $(document).on('click', '[data-modal]', function(event) {
        event.preventDefault();
        // Shows the correct fields on init
        function showContactTypeFields (modal) {
            
            var val = modal.find('select[name=type]').val();
            if(val.length <= 0) {
                val = 'person';
            }

            if(typeof val !== 'undefined') {
                modal.find('.detailGroup').hide();
                modal.find('.'+val).show();
            }
        }

        // Setup form values
        var btn = $(this);
        var modal = $(btn.data('modal'));
        var route = btn.data('route'); // Route is where the html to populate the modal comes from
        // Set the method to call if neccesary
        if(btn.data(method)){
            var method = btn.data('method');
        }

        // Populate modal html
        $.get(route, function(data) {
            modal.find('.modal-body').html(data);
            // Set the method
            if(typeof method !== 'undefined') {
                modal.find('form').attr('data-remote-on-success', method);
            }
            // Show contact details if needed
            showContactTypeFields(modal);
            initDates();
            modal.modal({
                backdrop: 'static',
                show: true
            });
        });

    });

    // Show correct contact type details in address book create/edit modals
    $(document).on('change', '#contactModal select[name=type]', function(event) {
        event.preventDefault();
        var modal = $(this).closest('.modal');
        var val = $(this).val();
        if(typeof val == 'undefined') {
            val = 'person';
        }
        modal.find('.detailGroup').hide();
        modal.find('.'+val).show();
    });

    // Use my address functionality to auto fill address book entry
    $(document).on('click', '#set-as-user-address', function(e) {
        var btn = $(this);
        var address = JSON.parse(btn.attr('data-address'));
        var form = $('#contactModal.modal.in form');
        $.each(address, function(name,value) { 
            form.find('input[name='+name+']').val(value); 
        });
        e.preventDefault();
    });
    $(document).on('change', '.addresslookup', function(e) {
        var input = $(this).find(':selected');
        var address = JSON.parse(input.attr('data-address'));
        var form = $(this).parents('form');
        $.each(address, function(name,value) { 
            form.find('input[name='+name+']').val(value); 
        });
        e.preventDefault();
    });
    
    $(document).on('click', '.postcode-lookup', function(e) {
        var $form = $(this).parents('form');
        var val = $(this).parents('form').find('#postcode').val();
        $form.find('.addresslookupholder').hide();
        $.get( "/postcodes/" + val, function( data ) {
            if($.isArray(data)) {
                $form.find('.addresslookup').html('');
                $form.find('.addresslookup').append('<option data-address=\'{}\'>Select an address...</option>');
                for (var i = 0; i < data.length; i++) {
                     $form.find('.addresslookup').append('<option data-address=\'' + JSON.stringify(data[i]) + '\'>' + data[i]['address_1'] + '</option>');
                }
            }
        $form.find('.addresslookupholder').show();
        }, "json" );
        e.preventDefault();
    });
    

    /**
     * Show contact select modal
     * Triggered by data-target click.
     * We can pass specific functions to call on success etc.
     */
    $(document).on('click', '[data-target="#contactSelectModal"]', function(e) {

        // Button that launched the modal
        var btn = $(this);

        var contactType = btn.attr('data-contact-type');

        var $modal = $('#contactSelectModal');

        // Reset the contact type
        $modal.attr('data-contact-type', null);
        // Show all tabs
        $modal.find('.nav-tabs li').show();
        // Remove any disabled options
        $modal.find('.contact-card').removeClass('disabled');

        // Check to see if we need to disable any of the 
        // contacts if they are in the same question
        if(checkForDuplicateContacts) {
            var question = $('.panel.active .question');
            if(question.length) {
                // Grab the question name
                var question_name = question.data('question');
                var filter = [];
                // Get any inputs that have that name
                $('.panel .question[data-question=' + question_name + ']').find('input[type=hidden]').each(function(){
                    filter.push($(this).val());
                });
                // Loop through the contact cards and add a disabled class
                filter.forEach(function(id){
                    $modal.find('.contact-card[data-contact-id="'+id+'"]').addClass('disabled');
                });
            }
        }

        // Show/hide the relevant tab
        if(contactType !== 'contact') {
            $modal.find('.nav-tabs li').hide();
            $modal.find('.nav-tabs li[data-contact-type='+contactType+']').show();
            $modal.find('.nav-tabs li[data-contact-type='+contactType+'] a').tab('show');
            // Set the contact type for the add new contact modal
            $modal.attr('data-contact-type', contactType);
        } else {
            $modal.find('.nav-tabs li').show();
            $modal.attr('data-contact-type', contactType);
        }
        
        // Link the modal to the panel
        var name = $(e.target).parents('.person-input').find('input[data-field-type=person]').data('name');
        var key = $(e.target).parents('.panel').attr('data-panel-key');
        $modal.attr('data-name', name);
        $modal.attr('data-parent-panel-key', key);

        // Check to see if we want to set any custom methods
        if (btn.attr('data-method')) {
            $modal.attr('data-method', btn.attr('data-method'));
        }
    });

    /**
     * Add a contact to a panel in a questionnaire when clicked in the contact modal.
     * OR, if we are on contacts page, add them to the trusted people list.
     */
    $(document).on('click', '[data-add-contact]', function(event) {

        event.preventDefault();

        if($(this).hasClass('disabled')) {
            return false;
        }

        // Get selected option
        var modal = $('#contactSelectModal');
        var id = $(this).data('contact-id');
        var btn = $(this);



        // If we are on the trusted people page, run the page specific function instead
        if($('.trusted-people-profile').length > 0) {
            return false;
        }

        if(id === null) {        
            alert('Please select a contact');
        } else {
            
            if(modal.attr('data-method')) {
            
                // A method has been set on the contact modal to call
                ModalMethods.addToAssetPanel(btn, modal);
            
            } else {
                // We are just adding this in the usual way

                // Get the input name
                var name = $('#contactSelectModal').attr('data-name');
                var panel = $('#contactSelectModal').attr('data-parent-panel-key');
                
                // Set the value to the selected value
                $('[data-panel-key='+panel+'] input[data-name="'+name+'"]').val(id).trigger('change');

                // Alter the button text to say change
                var btn = $('[data-panel-key='+panel+'] input[data-name="'+name+'"]').parents('.person-input').find(' button[data-contact-type]');
                btn.html('<i class="fa fa-edit"></i> Change ' + btn.attr('data-contact-type'));

                // Hide Modal
                modal.modal('hide');
            }
        }
    });

    /**
     * Edit contact.
     * Triggered from clicking the edit contact button in the contact select modal.
     * Launches new modal which is populated by a get request which returns raw html to populate the modal.
     */
    $(document).on('click', 'button[data-edit-contact]', function(e) {
        e.preventDefault();
        
        // Launch the add contact modal with the user details and edit button
        var $btn = $(this);
        var id = $btn.data('contact-id');
        var $modal = $btn.parents('.modal');
        var route = $modal.data('route');
    
        // Get the edit contact form html
        $.ajax({ url: route + '/' + id + '/edit' })
        .done(function(data) {
            // Populate the modal
            $('#contactModal .modal-body').html(data);
            // Change the on remote success
            $('#contactModal form').attr('data-remote-on-success', 'updateSelectContactModal');
            $('#contactModal').modal('show');

            $('#contactModal').find('.date-picker-format').datetimepicker({format:'DD/MM/YYYY', extraFormats: ['YYYY-MM-DD']});
            // Init any dates
            /*
            $('[type="date"]').each(function(){
                var t = $(this);
                if ( t.prop('type') != 'date' ) {
                    t.attr('data-not-native-date', true);
                    // Do some hooky stuff with the date format
                    if(t.val().length) {
                        var d = t.val().split('-');
                        t.val(d.reverse().join('/'));
                    }
                    // Init date picker
                    t.datetimepicker({
                        format: 'DD/MM/YYYY'
                    });
                }
            });  */
        });
    
    });

    /**
     * When contact modal is launched.
     * If trigger has data-subject set it will add that to the form
     */
    $('#contact-advice').on('show.bs.modal', function (e) {
      var modal = $(this);
      var btn = $(e.relatedTarget);
      var form = modal.find('form');
      if(btn.data('subject')) {
        modal.find('input[name=subject]').val(btn.data('subject') + ' advice');
      }
    });


    /**
     * Uploads
     */
    // Uploads modal
    $(document).on('click', '[data-asset-upload]', function(e){

        var btn = $(this);
        btn.prop('disabled', true);
        btn.find('i').addClass('fa-spinner fa-spin');

        var modal = $('#fileModal');
        modal.find('input[name=asset_id]').val(btn.data('assetId'));

        // Get the list of files
        $.get(btn.data('uploadRoute'), function(data) {

            if(data.length > 0) {

                appendFileRow(data);

            } else {
                
                // No files for this asset
                modal.find('.no-files').show();
            
            }
            modal.modal('show');
            btn.find('i').removeClass('fa-spinner fa-spin');
        });
        e.preventDefault();
    });


    /**
     * Acts on show of add new contact modal to show the correct 
     * input type (person/organisation)
     */
    $('#contactModal').on('show.bs.modal', function(e) {
        
        var select = $(this).find('select[name=type]');

        // Show if previously hidden
        select.parents('.form-group').show();

        // Check to see if we are coming from a contact select modal
        var $selectModal = $('.modal.in#contactSelectModal');

        if($selectModal.length > 0) {

            // Check to see if we have a type set
            if($selectModal.attr('data-contact-type')) {
                // Have to do this as we changed the type name of company/organisation
                // half way through. :_(
                if($selectModal.attr('data-contact-type') == 'contact') {
                    var type = 'contact';
                    // So we have something selected initially
                    select.val('person').trigger('change');
                } else {
                    var type = ($selectModal.attr('data-contact-type') == 'person') ? 'person' : 'company';
                    console.log(type, $selectModal.data('contactType'), $selectModal.attr('data-contact-type'));
                    // Set the type
                    select.val(type)
                        .trigger('change')
                        .parents('.form-group')
                        .hide();
                }
            }
        }
    });

    /**
     * Search the contact modal
     */
    $('#contactSelectModal input#filterContacts').on('keyup', function(){
        var term = $(this).val().toLowerCase(); 
        if(term == '') {
            $('#contactSelectModal .contact-container').show();
        } else {
            $('#contactSelectModal .contact-container').find('.name, .title').each(function(){
                var text = $(this).text().trim().toLowerCase();
                (text.indexOf(term) >= 0) ? $(this).parents('.contact-container').show() : $(this).parents('.contact-container').hide();         
            });
        }
        
    });

};

// Append files to list
var appendFileRow = function(data){

    var downloadRoute = $('[data-download-route]').attr('data-download-route');
    var deleteRoute = $('#fileModal').attr('data-route');
    var token = $('#fileModal').attr('data-token');

    // Check for quick upload and to see if the modal is on the page
    // BARF.
    if(data[0].group == 'Quick Upload' && $('#quickUploads.modal').length > 0) {
        var tb = $('#quickUploads.modal').find('tbody');
        $.each(data, function() {
            tb.append('<tr><td><a href="'+downloadRoute+'/'+this.id+'">' + this.name + '</a></td><td>Today</td><td><a data-file-id="'+this.id+'" class="btn btn-primary" data-toggle="modal" href="#moveQuickUpload"><i class="fa fa-folder-open"></i></a></td><td><form method="POST" data-remote data-model="Uploads" data-remote-on-success="removeQuickUploadRow" action="'+deleteRoute+'/'+this.id+'"><input type="hidden" name="_token" value="'+token+'" /><input type="hidden" name="_method" value="delete"><button type="submit" data-delete="' + this.id + '" data-confirm="Are you sure you wish to delete this upload?" class="btn btn-danger btn-sm"><i class="fa fa-trash-o"></i></button></form></td></tr>');
        });
        // Update count
        var c = $('[data-target="#quickUploads"]').find('span.count');
        var n = Number(c.text()); n++; c.text(n);
        // Hide no file
        $('#quickUploads .alert.alert-info').parent().hide();
    } else {
        if($('#fileModal').length > 0) {
            var ul = $('#fileModal').find('ul.user-files');
            var downloadRoute = $('[data-download-route]').attr('data-download-route');
            var deleteRoute = $('#fileModal').attr('data-route');
            var token = $('#fileModal').attr('data-token');
            $.each(data, function(){
                ul.append('<li><a href="'+downloadRoute+'/'+this.id+'">' + this.name + '</a><form class="pull-right" method="POST" data-remote data-model="Uploads" data-remote-on-success="removeRow" action="'+deleteRoute+'/'+this.id+'"><input type="hidden" name="_token" value="'+token+'" /><input type="hidden" name="_method" value="delete"><button type="submit" data-delete="' + this.id + '" data-confirm="Are you sure you wish to delete this upload?" class="btn btn-danger btn-sm pull-right"><i class="fa fa-trash-o"></i></button></form></li>');
            });
            ul.show();
        }
    }


    
}

$(document).ready(function() {

    modals();

    // File upload stuff. There's something gnarly and horrid
    // going on with the file modal that won't be namespaced...

    var uploadUrl;

    $(document).on('click', '.file-button', function(){
        uploadUrl = $(this).data('route');
        $('#fileupload').click();
    });

    $('#fileupload').fileupload({
        url: uploadUrl,
        dataType: 'json',
        complete: function(data) {
            appendFileRow(data.responseJSON);
        },
        progressall: function (e, data) {
            $('.progress').css('display', 'block');
            var progress = parseInt(data.loaded / data.total * 100, 10);
            $('.progress .progress-bar').css(
                'width',
                progress + '%'
            );
        },
        done: function(e, data){
            $('.progress').css('display', 'none');
        },
        stop: function(e) {
            swal({
                'text': 'File uploaded',
                'type': 'success'
            });
            $('#fileModal').find('.no-files').hide();
            $('#fileModal').find('[data-toggle=tab]a:first').tab('show');
        }
    }).prop('disabled', !$.support.fileInput)
    .parent()
    .addClass($.support.fileInput ? undefined : 'disabled');

    // Handle close modal - clear data
    $('#fileModal').on('hidden.bs.modal', function(e) {
        $(this).find('input[name=asset_id]').val('');
        $(this).find('ul.user-files, .no-files').hide();
        $(this).find('ul.user-files').empty();
        $('[data-asset-upload]').prop('disabled', false);
    });

});